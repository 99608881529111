import React from 'react';
import './data_analysis_page.css'
import apple_button from "../../img/appleButton.svg";
import google_button from "../../img/googleButton.svg";
import analysis_phone from "../../img/analysis_phone.svg";
import analysis_data from "../../img/analysis_data.svg";

const DataAnalysisPage = React.memo(() => {

    return (
        <div className="data_page">
            <div className='data_container'>
                <div style={{maxWidth: '1420px', display:'flex'}}>
                    <div className='data_page_block'>
                        <div style={{display:'flex', justifyContent:'center', width:'100%'}}>
                            <img src={analysis_data} alt=""/>
                        </div>
                        <div>
                        <div style={{textAlign: 'justify'}}>
                        One of the standout features of the Huntgate is its ability to categorize received images. Through the convenience of your mobile app, you can identify which wildlife species have been captured in your trail camera images. This effortless categorization takes the guesswork out of image analysis, letting you focus on what really matters – planning your hunt.
                        </div>
                        <br></br>
                        <div style={{textAlign: 'justify'}}>
                        But the Huntgate goes a step further, enhancing each captured image with a detailed record of weather conditions at the camera's location at the time of the shot. With information on temperature, wind direction and speed, moon phases, atmospheric pressure, and more at your fingertips, you can gain a comprehensive understanding of the environment in which your game is living. This data is meticulously sourced from reliable third-party service providers, ensuring that you have accurate, up-to-date information to inform your hunting strategy.
                        </div>
                        <br></br>
                        <div style={{textAlign: 'justify'}}>
                        The Huntgate doesn't stop at image categorization and weather data. It also provides valuable statistics based on the categorized information about observed wildlife at different times throughout the day. The app presents both basic and extended statistical data, providing you with an unparalleled level of detail about your hunting ground. These statistics can reveal patterns and behaviors of the game, allowing you to plan your hunting strategy with precision and insight.
                        </div>
                        <br></br>
                        <div style={{textAlign: 'justify'}}>
                        To access the extended statistics and unlock the full potential of Huntgate, users can purchase our premium services. Payments are made easy through Apple Pay and Google Play, and the app operates smoothly on both Apple iOS and Google Android operating systems.
                        </div>
                        <br></br>
                        <div style={{textAlign: 'justify'}}>
                        Experience the future of hunting with Huntgate. Automate your data analysis, streamline your strategy, and step into a new era of hunting, where technology and tradition merge to help you achieve your hunting goals. Whether you're a seasoned hunter or a beginner stepping into the field for the first time, Huntgate is your comprehensive guide to understanding and thriving in the natural world. Start observing, analyzing, and strategizing with Huntgate today!
                        </div>
                    </div>
                    </div>
                    <div style={{padding: '300px 10px'}}>
                    <img src={analysis_phone} alt=""/>
                    </div>
                </div>
            </div>
            <div className='section_container'>
                <div style={{padding: '60px 10px'}} className='section_page_phones'>
                    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <img style={{maxWidth: '50%', paddingRight: '20px'}} src={apple_button} alt=""/>
                        <img style={{maxWidth: '50%'}} src={google_button} alt=""/>

                    </div>
                </div>
            </div>
        </div>
    );
});

export default DataAnalysisPage;
