import React from 'react';
import './section_page.css'
import doublePhone from '../../img/Group 158.svg'
import line1 from '../../img/Vector 68.svg'
import line2 from '../../img/line2.svg'
import number1 from '../../img/number1.svg'
import apple_button from "../../img/appleButton.svg";
import google_button from "../../img/googleButton.svg";
import cameras_phone from "../../img/camerasPhone.svg";
import phone_img2 from "../../img/phone_img2.svg";
import phone_img3 from "../../img/phone_img3.svg";
import text_sort from "../../img/text_sort.svg";
import forcast_info from "../../img/forcast_info.svg";
import {Link} from "react-router-dom";

const SectionPage = React.memo(() => {

    return (
        <div className="section_page">
            <div className='section_container'>
                <div className='section_page_phones'>
                    <div style={{zIndex: '10'}}>
                        <p style={{fontSize: '40px', fontWeight: '600'}}>Automatic Data Analysis</p>
                        <p style={{fontSize: '20px', fontWeight: '400', lineHeight: '30px'}}>The application shows the
                            activity of
                            animals at <div style={{display: 'flex'}}>{'different times of '}
                                <span style={{display: 'flex', flexDirection: 'column'}}>
                                    {' the day, weather conditions and'}
                                    <img
                                        style={{maxWidth: '100%'}} src={line1} alt=""/></span></div>
                            <span style={{
                                fontSize: '20px',
                                fontWeight: '400',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: '-10px',
                            }}><span>moon phases.</span><img
                                style={{maxWidth: '120px'}} src={line2} alt=""/></span>
                        </p>
                        <Link className="learn_more" to='/analysis'>Learn more</Link>
                    </div>
                    <img style={{marginTop: '-120px'}} src={doublePhone} alt=""/>
                </div>
            </div>
            <div style={{backgroundColor: '#E5E5E5'}} className='section_container'>
                <div style={{flexDirection: 'row'}} className='section_page_phones'>
                    <div className='section_page_phone_block'>
                        <div style={{paddingBottom: '30px'}}>
                            <p style={{fontSize: '40px', fontWeight: '600'}}>Creating an account<br/> takes less than a
                                minute</p>
                            <p style={{fontSize: '20px', fontWeight: '400'}}>Quick registration by phone number and
                                minimal<br/> input of information</p>
                            <Link className="learn_more" to='/account'>Learn more</Link>
                        </div>
                        <img src={number1} alt=""/>
                    </div>
                    <div className='section_page_phone_block'>
                        <div style={{paddingBottom: '120px'}}>
                            <p style={{fontSize: '40px', fontWeight: '600'}}>Easy and quick to register<br/> your Trail
                                Cameras</p>
                            <p style={{fontSize: '20px', fontWeight: '400'}}>Each camera has its own mail, to which all
                                photos<br/> are sent. You can share cameras and photos with<br/> your friends.</p>
                            <Link className="learn_more" to='/register'>Learn more</Link>
                        </div>
                        <img src={cameras_phone} alt=""/>
                    </div>
                    <div style={{flexDirection: 'row', marginTop: '50px', marginBottom: '50px'}}
                         className='section_page_phone_block'>
                        <div style={{display: 'flex', paddingRight: '100px'}}>
                            <img src={text_sort} alt=""/>
                            <img style={{
                                marginLeft: '-20%',
                                marginTop: '300px'
                            }} src={phone_img2} alt=""/>
                        </div>
                        <img src={phone_img3} alt=""/>
                    </div>
                    <div style={{marginBottom: '50px', width: '100%', alignItems: 'center', paddingBottom: '80px'}}
                         className='section_page_phone_block'>
                        <div style={{paddingBottom: '90px'}}>
                            <p style={{fontSize: '40px', fontWeight: '600'}}>Weather & Astro Data</p>
                            <p style={{fontSize: '20px', fontWeight: '400'}}>Learn patterns in animal activity. Does
                                rain, cold, or<br/> the phase of the moon affect animal activity?</p>
                            <Link className="learn_more" to='/weather'>Learn more</Link>
                        </div>
                        <img style={{width: '50%'}} src={forcast_info} alt=""/>
                    </div>
                </div>
            </div>
            <div className='section_container'>
                <div style={{padding: '60px 10px'}} className='section_page_phones'>
                    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <img style={{maxWidth: '50%', paddingRight: '20px'}} src={apple_button} alt=""/>
                        <img style={{maxWidth: '50%'}} src={google_button} alt=""/>

                    </div>
                </div>
            </div>
        </div>
    );
});

export default SectionPage;
