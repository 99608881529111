import './header.css';
import React from "react";
import {Link} from "react-router-dom";
import logo from "../../img/Logo.svg";


const Header = () => {
    return (
        <div className="header">
            <div className="container header_container">
                <div><img src={logo} alt=""/></div>
                <div style={{display: 'flex'}}>
                    <Link
                        style={{paddingRight: '30px', color: '#000000', textDecoration: 'none'}}
                        to="/"
                    >
                        <p>Home</p>
                    </Link>
                    <Link
                        style={{color: '#000000', textDecoration: 'none'}}
                        to="/section"
                    >
                        <p>Sections</p>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Header;
