import React from 'react';
import './data_page.css'
import apple_button from "../../img/appleButton.svg";
import google_button from "../../img/googleButton.svg";
import your_data from "../../img/Group 171 1.svg";

const DataPage = React.memo(() => {

    return (
        <div className="data_page">
            <div className='data_container'>
                <div className='data_page_block'>
                    <div style={{display:'flex', justifyContent:'center', width:'100%'}}>
                   
                    </div>
                    <div>
                        <h1>Privacy Policy and Terms of Use for the "Huntgate" mobile application</h1>
                    </div>
                    <div>
                        <p>Preamble</p>
                        <div style={{textAlign: 'justify'}}>
                            These Terms of Use and Privacy Policy (collectively referred to as "Terms") govern the use of the "Huntgate" mobile application (the "App"), developed by aQode Ltd ("aQode", "we", "our", or "us"). By using the App, you agree to these Terms.
                        </div>
                    </div>
                    <div>
                        <p>The Service</p>
                        
						<div style={{textAlign: 'justify'}}>
						The App is intended for trail cameras, which send captured wildlife photos to registered email boxes on the Huntgate email server. Users can register for an account via mobile phone verification using a one-time access code received by SMS or by creating an account using Facebook or Google accounts.
						</div>
						<div style={{textAlign: 'justify'}}>
                        The App processes incoming emails, allows users to sort received images, and specify which wildlife is visible in the photos. In addition to each received photo, weather conditions from the location of the trail camera are recorded, including but not limited to temperature, wind direction and speed, moon phases, air pressure, and other indicator.
                        </div>
                    </div>
                    <div>
                        <p>Data Processing</p>
                        <div style={{textAlign: 'justify'}}>
                        The company responsible for data processing is aQode Ltd. The App collects and processes both user-generated and system-generated data to provide a better experience and service. This data includes but is not limited to:
						<ul>
					    <li>Email Addresses</li>
						 <li>Mobile Numbers</li>
						  <li>Wildlife Images</li>
						   <li>Weather Data</li>
						   <li>User Preferences and Settings</li>
						   
						</ul>
                        </div>
                    </div>
                    <div>
                        <p>Privacy Policy</p>	
                        <div style={{textAlign: 'justify'}}>
                        <b>Data Storage</b><br></br>
						The images and data are stored securely in the Amazon Web Services (AWS) data warehouse. Weather information is obtained from third-party service providers.
                        </div>
						<div style={{textAlign: 'justify'}}>
                        <br></br><b>Data Sharing</b><br></br>
						aQode Ltd does not share your personal data with any third parties for marketing purposes without your explicit consent. However, data may be shared with third-party service providers for operational needs under strict data protection clauses.
                        </div>
						<div style={{textAlign: 'justify'}}>
                        <br></br><b>Subscription Service</b><br></br>
						To access expanded statistics, a paid subscription service is available for a monthly fee, payable through Apple Pay and Google Pay.
                        </div>
						<div style={{textAlign: 'justify'}}>
                        <br></br><b>Sharing Function</b><br></br>
						Users can share their registered trail cameras with trusted users by specifying another user's email address or mobile phone number. Shared users will have access to sort these camera photos and access both simple and expanded statistics.
                        </div>
						<div style={{textAlign: 'justify'}}>
                        <br></br><b>Data and User Profile Deletion</b><br></br>
						<ul>
					    <li><b>Data Deletion:</b> Users can delete their trail cameras and all associated information within the App. Deletion is irreversible and permanent.</li>
						 <li><b>User Profile Deletion:</b> Users can delete their entire user profile, which will also result in the deletion of all data and settings.</li>
						</ul>
                        </div>
						<div style={{textAlign: 'justify'}}>
                       <br></br><b> Cookies and Tracking</b><br></br>
						The App may use cookies and similar technologies to improve user experience. By using the App, you consent to the use of these technologies for analytics and improved functionality.
                        </div>
						<div style={{textAlign: 'justify'}}>
                        <br></br><b>User Rights</b><br></br>
						You have the right to request access to, correction or deletion of, the data we have about you. You can do this by contacting us via the App's settings or directly at huntgate@huntgate.app.
                        </div>
                    </div>
                    <div>
                        <p>Platform Availability</p>
                        <div style={{textAlign: 'justify'}}>
                        The App is available for Apple iOS and Google Android operating systems.
                        </div>
                    </div>
                    <div>
                        <p>Updates and Changes</p>
                        <div style={{textAlign: 'justify'}}>
                        aQode reserves the right to modify these Terms, the features of the App, the services offered, and any associated pricing at any time. Continued use of the App after such changes implies acceptance of the new terms and conditions.
                        </div>
                    </div>
                    <br></br>
                    <div style={{textAlign: 'justify'}}>
                    By using the App, you agree to these Terms.
                    </div>
                    <br></br>
                    <div style={{textAlign: 'justify'}}>
                    Please contact aQode Ltd huntgate@huntgate.app for any questions regarding these Terms or data processing. 
                    </div>
                </div>
            </div>
            <div className='section_container'>
                <div style={{padding: '60px 10px'}} className='section_page_phones'>
                    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <img style={{maxWidth: '50%', paddingRight: '20px'}} src={apple_button} alt=""/>
                        <img style={{maxWidth: '50%'}} src={google_button} alt=""/>

                    </div>
                </div>
            </div>
        </div>
    );
});

export default DataPage;
