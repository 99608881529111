import React from 'react';
import './footer.css';
import {Link} from 'react-router-dom';

const Footer = () => {

    return (
        <div className="footer">
            <div className="container footer-container">
                <div className="footer-links">
                    <Link
                        rel="noopener noreferrer"
                        className="footer-link"
                        to='/data'
                    >
                        Your data
                    </Link>
                    <Link
                        rel="noopener noreferrer"
                        className="footer-link"
                        to='/contact'
                    >
                        Contact
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Footer;
