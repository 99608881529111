import React from 'react';
import './home_page.css';
import phone from '../../img/Mockup.svg'
import graphic from '../../img/graphic.svg'
import apple_button from '../../img/appleButton.svg'
import google_button from '../../img/googleButton.svg'
import vector1 from '../../img/Vector 57.svg'
import vector2 from '../../img/Vector 63.svg'
import text2 from '../../img/activity_animals2.svg'
import img1 from '../../img/1.svg'
import img2 from '../../img/2.svg'
import img3 from '../../img/3.svg'
import img4 from '../../img/4.svg'
import vector3 from '../../img/Vector1svg.svg'
import vector4 from '../../img/Vector2.svg'
import groupPhone from '../../img/Group 151.svg'
import left_animal_phone from '../../img/Frame 154.svg'
import right_animal_phone from '../../img/Frame 155.svg'
import animal_photo1 from '../../img/animal_photo1.svg'
import animal_photo2 from '../../img/animal_photo2.svg'
import plan1 from '../../img/Frame 152.svg'
import plan2 from '../../img/Frame 153.svg'


const HomePage = React.memo(() => {

    return (
        <div className="home_page">
            <div className='homeContainer'>
                <div className='home_page_phone_container'>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <p style={{fontSize: '48px', fontWeight: '600', paddingBottom: '20px'}}>Tracking animal activity
                            just got easier</p>
                        <p style={{fontSize: '18px', fontWeight: '400', textAlign: 'justify'}}>Experience the future of hunting with Huntgate! Our innovative mobile app integrates with trail cameras, capturing and organizing wildlife images to optimize your hunting strategy. Get detailed weather reports from your camera's location, analyze wildlife patterns, and gain insights into your hunting ground. Register using your mobile phone, Facebook, or Google account. Access advanced statistics through our premium services to enhance your hunting plan. Join Huntgate.app - make your hunt smarter!</p>
                        <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
                            <a href="https://apps.apple.com/lv/app/huntgate/id6449548365" target="_blank"><img style={{maxWidth: '100%'}} src={apple_button} alt=""/></a>
                            <a href="https://play.google.com/store/apps/details?id=app.huntgate.huntgate" target="_blank"><img style={{maxWidth: '100%'}} src={google_button} alt=""/></a>

                        </div>
                    </div>
                    <div><img style={{padding: '10px 0px'}} src={phone} alt=""/></div>
                    <div style={{flexDirection: "column"}}>
                        <img style={{paddingBottom: '10px'}} src={graphic} alt=""/>
                        <img style={{marginLeft: '100px'}} src={vector1} alt=""/>
                        <img style={{paddingBottom: '10px', paddingTop: '10px'}} src={text2} alt=""/>
                        <img style={{marginRight: '200px'}} src={vector2} alt=""/>
                    </div>
                </div>
            </div>
            <div style={{backgroundColor: '#E5E5E5'}} className='homeContainer'>
                <div className='home_page_why_container'>
                    <div><p style={{fontSize: '40px', fontWeight: '600'}}>Why Huntgate?</p></div>
                    <div className='home_page_why_container_block'>
                        <div className='container_column'>
                            <div className='vector_block'>
                                <img src={img1} alt=""/>
                                <img style={{marginLeft: '10px'}} src={vector4} alt=""/>
                            </div>
                            <p style={{fontSize: '20px', fontWeight: '600'}}>Creating an account takes less than a
                                minute</p>
                            <p style={{fontSize: '16px', fontWeight: '400', paddingTop: '10px'}}>Signing up for the Huntgate app is quick and hassle-free. It takes less than a minute to create your account, allowing you to get started with managing your trail cameras without any unnecessary delays.</p>
                        </div>
                        <div className='container_column'>
                            <div className='vector_block'>
                                <img src={img2} alt=""/>
                                <img style={{marginLeft: '10px'}} src={vector3} alt=""/>
                            </div>
                            <p style={{fontSize: '20px', fontWeight: '600'}}>Easy and quick to register your Trail
                                Cameras</p>
                            <p style={{fontSize: '16px', fontWeight: '400', paddingTop: '10px'}}>With the Huntgate app, registering your trail cameras is a breeze. The user-friendly interface and intuitive setup process make it easy to connect and configure your cameras, ensuring a seamless experience from the start.</p>
                            {/*<p style={{fontSize:'20', fontWeight:'400'}}>fff</p>*/}
                        </div>
                        <div className='container_column'>
                            <div className='vector_block'>
                                <img src={img3} alt=""/>
                                <img style={{marginLeft: '10px'}} src={vector4} alt=""/>
                            </div>
                            <p style={{fontSize: '20px', fontWeight: '600'}}>Sort in one click</p>
                            <p style={{fontSize: '16px', fontWeight: '400', paddingTop: '10px'}}>The Huntgate app simplifies the process of sorting your wildlife photos. With just one click, you can categorize and organize the received images based on the identified animal species, saving you valuable time and effort.</p>
                            {/*<p style={{fontSize:'20', fontWeight:'400'}}>fff</p>*/}
                        </div>
                        <div className='container_column'>
                            <div className='vector_block'>
                                <img src={img4} alt=""/>
                                <img style={{marginLeft: '10px'}} src={vector3} alt=""/>
                            </div>
                            <p style={{fontSize: '20px', fontWeight: '600'}}>Automatic Data Analysis</p>
                            <p style={{fontSize: '16px', fontWeight: '400', paddingTop: '10px'}}>The app offers automatic data analysis, allowing you to gain valuable insights without manual calculations. By leveraging advanced algorithms and machine learning capabilities, the Huntgate app provides both simple and comprehensive statistical reports, enabling you to understand the behavior and patterns of forest animals captured by your trail cameras.</p>
                            {/*<p style={{fontSize:'20', fontWeight:'400'}}>fff</p>*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className='homeContainer'>
                <div className='home_page_why_container'>
                    <div><p style={{fontSize: '40px', fontWeight: '600'}}>Fast and Intuitive sorting</p></div>
                    <div style={{display:'flex', justifyContent:'center'}}><img src={groupPhone} alt=""/></div>
                </div>
            </div>
            <div style={{backgroundColor: '#E5E5E5'}} className='homeContainer'>
                <div style={{padding: '40px 5px'}}>
                    <img style={{padding: '10px'}} src={right_animal_phone} alt=""/>
                    <img style={{padding: '10px'}} src={left_animal_phone} alt=""/>
                </div>
            </div>
            <div className='homeContainer'>
                <div style={{alignItems: 'center'}} className='home_page_why_container'>
                    <p style={{maxWidth: '420px', fontSize: '40px', fontWeight: '600'}}>You can zoom in on the photo and
                        take a closer look at the animal</p>
                    <span style={{maxWidth: '420px', fontSize: '20px', fontWeight: '400'}}>Recognizing an animal has become easier for<br/> the user using <span
                        style={{color: '#F1633A', textDecoration: 'underline'}}>the zoom</span> function</span>
                    <div style={{paddingTop: '50px'}}>
                        <img style={{padding: '10px'}} src={animal_photo2} alt=""/>
                        <img style={{padding: '10px'}} src={animal_photo1} alt=""/>
                    </div>
                </div>
            </div>
            <div style={{backgroundColor: '#E5E5E5'}} className='homeContainer'>
                <div style={{
                    margin: '50px 0px',
                    padding: '0px 100px',
                    backgroundColor: 'rgb(248, 248, 248)',
                    borderRadius: '20px'
                }}>
                    <p style={{fontSize: '40px', fontWeight: '600'}}>2 current plans of app</p>
                    <img src={plan1} alt=""/>
                    <img src={plan2} alt=""/>
                </div>
            </div>
        </div>
    );
});

export default HomePage;
