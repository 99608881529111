import React from 'react';
import './contact.css'
import apple_button from "../../img/appleButton.svg";
import google_button from "../../img/googleButton.svg";
import your_data from "../../img/Group 171 1.svg";

const DataPage = React.memo(() => {

    return (
        <div className="data_page">
            <div className='data_container'>
                <div className='data_page_block'>
                    <div>
                        <p>Concat</p>
                        <div style={{textAlign: 'justify'}}>
                            <b>Company Name:</b> aQode Ltd. <br></br>
                            <b>Registration Number:</b> 40203292711<br></br>
                            <b>Registered Address:</b> 4A-1 Kastrānes Street, Riga, LV-1039<br></br>
                            <b>Email:</b> huntgate@huntgate.app<br></br>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section_container'>
                <div style={{padding: '60px 10px'}} className='section_page_phones'>
                    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <img style={{maxWidth: '50%', paddingRight: '20px'}} src={apple_button} alt=""/>
                        <img style={{maxWidth: '50%'}} src={google_button} alt=""/>

                    </div>
                </div>
            </div>
        </div>
    );
});

export default DataPage;
